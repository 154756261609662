import axios from 'axios';
import { IUser } from '@all-types/csat-types';
import { toaster } from '@ui/dialogs/ToastErrorMessage';

const apiUrl = process.env.REACT_APP_API_URL as string;

export default class UserAPI {
  // eslint-disable-next-line no-use-before-define
  private static instance: UserAPI;
  private host = apiUrl;

  private api: any;

  private constructor() {
    const token = localStorage.getItem('auth-token');
    this.api = axios.create({
      baseURL: this.host,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  public static getInstance(): UserAPI {
    if (!UserAPI.instance) {
      UserAPI.instance = new UserAPI();
    }

    return UserAPI.instance;
  }

  public async getUser(): Promise<IUser | null> {
    const response = await this.api.get('/auth/me').catch((error: any) => {
      toaster.error({ text: 'Cannot sync user. Try to refresh the page' });
      console.error(error.toJSON());
    });
    if ([200, 201].includes(response?.status)) {
      return response.data;
    }
    return null;
  }
}

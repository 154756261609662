import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Button, ButtonVariant } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Plus } from '@icons';
import Switcher from '@ui/switcher/Switcher';
import AddScriptDialog from '@ui/dialogs/AddScriptDialog';
import { Form } from '@/components/ui/form';
import InputForm from '@ui/forms/InputForm';

const FormSchema = z.object({
  scriptName: z.string().min(2, {
    message: 'Script name must be at least 2 characters.'
  })
});

export default function ScriptDialogInfo() {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema)
  });

  function onSubmit(values: z.infer<typeof FormSchema>) {}

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="bg-main hover:bg-lightBlue" size="sm">
          <Plus />
          Add new
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[572px] ">
        <DialogHeader>
          <DialogTitle>New Script</DialogTitle>
        </DialogHeader>
        <hr className="w-full" />
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-4 py-4">
              <div className="items-center gap-4 pb-6">
                <div className="pb-2">
                  <Label htmlFor="name" className="text-right">
                    Name
                  </Label>
                </div>
                <InputForm
                  control={form.control}
                  name="scriptName"
                  type="text"
                  placeholder="example"
                  className="col-span-3 pt-2 !h-8 text-sm"
                />
              </div>
              <div className="pb-6 items-center gap-4">
                <div className="pb-2">
                  <div className="pb-2">
                    <Label htmlFor="status" className="text-right">
                      Status
                    </Label>
                  </div>
                </div>
              </div>
              <div className="pb-6 items-center gap-4">
                <div className="pb-2">
                  <Label htmlFor="team" className="text-right">
                    Add team
                    <label htmlFor="" className="text-gray-300 ml-2 text-sm">
                      (optional)
                    </label>
                  </Label>
                </div>
                <Input
                  name="team"
                  id="team"
                  placeholder="Please select"
                  className="col-span-3 !h-8"
                />
              </div>
              <div className="pb-6 items-center gap-4">
                <div className="pb-2">
                  <Label htmlFor="analytics" className="text-right text-sm">
                    Collect analytics
                  </Label>
                </div>
                <Switcher />
              </div>
            </div>
            <hr className="w-full pb-6" />
            <DialogFooter>
              <DialogClose>
                <Button
                  variant={ButtonVariant.OUTLINE}
                  className="border"
                  size="sm">
                  Cancel
                </Button>
              </DialogClose>
              <AddScriptDialog
                trigger={
                  <DialogTrigger asChild>
                    <Button
                      disabled={
                        !form.formState.isValid || form.formState.isSubmitting
                      }
                      type="submit"
                      className="text-white bg-main"
                      size="sm">
                      Ok
                    </Button>
                  </DialogTrigger>
                }
              />
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

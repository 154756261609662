import React from 'react';
import Selector from '@ui/selector/Selector';
import { days, period, scripts, teams } from '@utils/selectHelpers';
import { ExportButtonCSV } from '@icons';
import { Button, ButtonVariant } from '@/components/ui/button';

const onSelect = (value: string) => {};

const DashboardFilters = () => {
  const handleClickExportCSW = () => {};

  return (
    <div className="sm:px-0 flex w-full flex-wrap flex-row justify-between">
      <Selector
        className="md:ml-1 font-semibold mb-2 md:text-sm text-[10px]"
        placeholder="Days"
        onChange={onSelect}
        options={days}
        disabled
      />
      <label
        htmlFor=""
        className="md:ml-1 md:flex hidden md:mx-6 mx:0 md:text-sm text-[10px] mb-2 items-center">
        compared to
      </label>
      <Selector
        className="md:ml-1 mb-2 md:text-sm md:mr-2 text-[10px]"
        placeholder="Choose period"
        onChange={onSelect}
        options={period}
        disabled
      />
      <Selector
        className="md:ml-1 mb-2 md:text-sm text-[10px]"
        placeholder="All Team"
        onChange={onSelect}
        options={teams}
        disabled
      />
      <Selector
        className="md:ml-1 font-semibold mb-2 md:text-sm text-[10px]"
        placeholder="All offices"
        onChange={onSelect}
        options={scripts}
        disabled
      />
      <Button
        variant={ButtonVariant.SECONDARY}
        className="md:ml-1 md:!w-[128px] ml-auto md:!h-9 h-10 cursor-pointer border md:text-sm text-[10px]"
        onClick={handleClickExportCSW}
        disabled>
        <ExportButtonCSV /> ExportCSV
      </Button>
    </div>
  );
};

export default DashboardFilters;

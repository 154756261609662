import { RefObject, useEffect } from 'react';

const useOutsideClick = (
  refs: RefObject<HTMLElement>[],
  callback: () => void
) => {
  const handleClickOutside = (event: MouseEvent) => {
    for (const ref of refs) {
      if (ref.current && ref.current.contains(event.target as Node)) {
        return;
      }
    }
    callback();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, callback]);
};

export default useOutsideClick;

import React, { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger
} from '@/components/ui/dialog';
import { Button, ButtonVariant } from '@/components/ui/button';
import ReportNotificationsDialog from '@ui/dialogs/ReportNotificationsDialog';

interface IPDFPreview {
  exportToPDF: () => void;
  pdfBlobUrl: string | null;
  setPdfBlobUrl: (url: string | null) => void;
  isDisabled?: boolean;
}

const PdfPreview: FC<IPDFPreview> = ({
  pdfBlobUrl,
  setPdfBlobUrl,
  exportToPDF,
  isDisabled = false
}) => {
  return (
    <Dialog
      open={!!pdfBlobUrl}
      onOpenChange={(open) => !open && setPdfBlobUrl(null)}>
      <DialogTrigger disabled={isDisabled}>
        <Button
          disabled={isDisabled}
          variant={ButtonVariant.DEFAULT}
          onClick={exportToPDF}>
          Export to PDF
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[80vw]">
        <DialogHeader>
          <h2 className="text-lg font-semibold text-gray-800">PDF Preview</h2>
        </DialogHeader>
        <div>
          <iframe
            src={pdfBlobUrl!}
            title="PDF Preview"
            className="w-full h-[80vh]"
          />
        </div>
        <DialogFooter className="flex justify-end gap-3">
          <Button
            variant={ButtonVariant.OUTLINE}
            onClick={() => setPdfBlobUrl(null)}
            className="text-gray-500 hover:text-gray-800">
            Close
          </Button>
          <ReportNotificationsDialog
            onApply={() => setPdfBlobUrl(null)}
            maxCharacters={30}>
            <Button variant={ButtonVariant.DEFAULT}>Send Report</Button>
          </ReportNotificationsDialog>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PdfPreview;

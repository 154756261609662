import { useAuth0 } from '@auth0/auth0-react';

const useLogout = () => {
  const { logout } = useAuth0();

  const logoutWithRedirect = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
    localStorage.removeItem('auth-token');
    localStorage.removeItem('currentSpaceId');
  };
  return {
    logoutWithRedirect
  };
};

export default useLogout;

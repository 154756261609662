import React from 'react';
import useLogout from '@hooks/useLogout';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader
} from '@/components/ui/dialog';
import { MdAdminPanelSettings } from 'react-icons/md';

const AdminApproveDialog: React.FC = (): JSX.Element => {
  const { logoutWithRedirect } = useLogout();

  return (
    <Dialog open={true} onOpenChange={logoutWithRedirect}>
      <DialogContent onInteractOutside={(e) => e.preventDefault()}>
        <DialogHeader className="text-2xl font-semibold text-gray-600">
          Request sent
        </DialogHeader>
        <div className="flex gap-2 items-center">
          <div>
            <MdAdminPanelSettings size={48} className="text-main" />
          </div>
          <div>
            Your admin has been notified of your request to access this app.
            Once your admin reviews the request you will be notified via email.
          </div>
        </div>
        <DialogFooter>
          <DialogClose>
            <Button onClick={logoutWithRedirect}>Back to login</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AdminApproveDialog;

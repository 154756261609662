import React, { FC, useMemo, useRef, useState } from 'react';
import { groupRatingData, groupTrendsData } from '@utils/chartHelper';
import { ISentimentOverTime } from '@all-types/csat-types';
import { getChartData } from '@utils/csatHelpers';
import html2pdf from 'html2pdf.js';
import RaceBarChart from '@ui/charts/RaceBarChart';
import AreaChart from '@ui/charts/AreaChart';
import SkeletonGroup from '@ui/layout/SkeletonGroup';
import PDFPreview from '@ui/csat-transcription/tabs/reports/PDFPreview';
import { AiOutlineFileExclamation } from 'react-icons/ai';

interface IReportsTab {
  reportSummary: JSX.Element | null;
  insightsReport: JSX.Element | null;
  isReportSummaryLoading: boolean;
  isInsightsReportLoading: boolean;
  avgScoreByCategory: any;
  isRatingEnabled: boolean;
  sentimentByTopic: any;
  isAvgScoreByCategoryLoading: boolean;
  isSentimentOverTimeLoading: boolean;
  isSentimentByTopicLoading: boolean;
  sentimentOverTime?: ISentimentOverTime | null;
}

const ReportsTab: FC<IReportsTab> = ({
  avgScoreByCategory,
  reportSummary,
  isReportSummaryLoading,
  isAvgScoreByCategoryLoading,
  isRatingEnabled,
  insightsReport,
  isInsightsReportLoading,
  isSentimentOverTimeLoading,
  sentimentByTopic,
  isSentimentByTopicLoading,
  sentimentOverTime
}) => {
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const isDisabledPreview = isInsightsReportLoading || isReportSummaryLoading;

  const avgScoreByCategoryData = useMemo(
    () => getChartData(avgScoreByCategory?.category),
    [avgScoreByCategory]
  );
  const sentimentOverTimeData = useMemo(
    () => groupTrendsData(sentimentOverTime),
    [sentimentOverTime]
  );
  const sentimentByTopicData = useMemo(
    () => ({
      data: groupRatingData(sentimentByTopic),
      keys: Object.keys(sentimentByTopic || {}).filter((key) => key !== 'total')
    }),
    [sentimentByTopic]
  );

  const isLoadingOrHasData = useMemo(
    () =>
      (insightsReport && reportSummary) ||
      isInsightsReportLoading ||
      isReportSummaryLoading,
    [
      insightsReport,
      reportSummary,
      isInsightsReportLoading,
      isReportSummaryLoading
    ]
  );

  const exportToPDF = () => {
    if (targetRef.current) {
      const element = targetRef.current;
      const options = {
        filename: 'report.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        jsPDF: {
          unit: 'px',
          format: [element.offsetWidth, element.offsetHeight],
          orientation: 'portrait'
        }
      };
      html2pdf()
        .set(options)
        .from(element)
        .outputPdf('blob')
        .then((pdfBlob: Blob) => {
          const blobUrl = URL.createObjectURL(pdfBlob);
          setPdfBlobUrl(blobUrl);
        });
    }
  };

  return (
    <div className="h-full w-full">
      {isLoadingOrHasData ? (
        <div>
          <div className="w-full flex items-center justify-center pb-5">
            <span className="text-3xl pl-[118px] mx-auto w-fit text-center">
              Space report
            </span>
            <PDFPreview
              isDisabled={isDisabledPreview}
              exportToPDF={exportToPDF}
              pdfBlobUrl={pdfBlobUrl}
              setPdfBlobUrl={setPdfBlobUrl}
            />
          </div>
          <div className="w-full" ref={targetRef}>
            <div className="w-full bg-white mb-5 p-5 border border-gray-100 flex">
              <div className="w-full bg-white">
                {!isReportSummaryLoading && reportSummary ? (
                  <div className="text-start">
                    <h1 className="text-gray-500 font-bold text-[19px]">
                      Summary
                    </h1>
                    {reportSummary}
                  </div>
                ) : (
                  <SkeletonGroup count={1} />
                )}
              </div>
            </div>
            <div className="flex space-x-5">
              <div className="w-1/2 bg-white p-5 border border-gray-100">
                {!isInsightsReportLoading && insightsReport ? (
                  <div className="text-start">
                    <h1 className="text-gray-500 font-bold text-[19px]">
                      Insights
                    </h1>
                    {insightsReport}
                  </div>
                ) : (
                  <SkeletonGroup count={4} />
                )}
              </div>
              <div className="w-1/2 space-y-5">
                {!!sentimentOverTimeData.categories.length && (
                  <AreaChart
                    wrapperClassName="w-full md:h-[440px] h-[410px]"
                    title="Sentiment over Time"
                    data={sentimentOverTimeData.data}
                    categories={sentimentOverTimeData.categories}
                    isLoading={isSentimentOverTimeLoading}
                  />
                )}
                {isRatingEnabled && (
                  <RaceBarChart
                    wrapperClassName="w-full md:h-[440px] h-[410px]"
                    data={avgScoreByCategoryData}
                    title="Average Score by Category"
                    yAxisLabel="Score"
                    isLoading={isAvgScoreByCategoryLoading}
                  />
                )}
                <RaceBarChart
                  wrapperClassName="w-full md:h-[440px] h-[410px] break-inside-avoid"
                  data={sentimentByTopicData.data}
                  title="Sentiment by Category"
                  yAxisLabel="Percentage"
                  stacking="percent"
                  categories={sentimentByTopicData.keys}
                  isLoading={isSentimentByTopicLoading}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center !h-[calc(100vh-232px)] w-full text-gray-500 text-xl font-semibold">
          <AiOutlineFileExclamation size={100} className="mb-2" />
          This report has no data.
        </div>
      )}
    </div>
  );
};

export default ReportsTab;

import React, { FC, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { cn } from '@/lib/utils';
import { Button, ButtonVariant } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { IOption, ITree } from '@all-types/ui-types';
import {
  onCheckedChange,
  parseCategories,
  transformCategories
} from '@utils/checkboxHelper';

const FormSchema = z.object({
  items: z.record(z.string(), z.array(z.string()))
});

interface ICheckboxTreeProps {
  options: IOption[];
  onChange: (value: any) => void;
  onSubmit?: (data: z.infer<typeof FormSchema>) => void;
  className?: string;
  withSubmitButton?: boolean;
  defaultValues?: ITree | string[];
}

const CheckboxTree: FC<ICheckboxTreeProps> = ({
  className,
  options,
  withSubmitButton = true,
  onSubmit = () => {},
  onChange,
  defaultValues = {}
}) => {
  const items = Array.isArray(defaultValues)
    ? parseCategories(defaultValues)
    : defaultValues;

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: { items },
    values: { items }
  });

  useEffect(() => {
    const subscription = form.watch((value) => {
      onChange(transformCategories(value.items as ITree));
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  // Recursive function to render options and their sub-options
  const renderOptions = (options: IOption[], parentId = '') => {
    return options.map((item) => (
      <div key={item.id}>
        <FormField
          control={form.control}
          name="items"
          render={({ field }) => {
            const currValue = (field.value || {}) as unknown as ITree;
            const isChecked = parentId
              ? currValue[parentId]?.includes(item.id)
              : !!currValue[item.id];
            return (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0 hover:cursor-pointer hover:opacity-60">
                <FormControl>
                  <Checkbox
                    checked={isChecked}
                    onCheckedChange={(checked) =>
                      onCheckedChange(
                        checked,
                        field.onChange,
                        parentId,
                        currValue,
                        item
                      )
                    }
                    className={cn({
                      'bg-main border-none': isChecked
                    })}
                  />
                </FormControl>
                <FormLabel className="hover:cursor-pointer text-sm font-normal text-left">
                  {item.label}
                </FormLabel>
              </FormItem>
            );
          }}
        />
        {item.subOptions && item.subOptions.length > 0 && (
          <div className="ml-4 mt-1 flex flex-col space-y-1">
            {renderOptions(item.subOptions, item.id)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className={cn('max-h-[400px] overflow-auto', className)}>
            <FormField
              control={form.control}
              name="items"
              render={() => (
                <FormItem>
                  {renderOptions(options)}
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex justify-end">
            {withSubmitButton && (
              <Button
                variant={ButtonVariant.SECONDARY}
                className="font-bold"
                size="sm"
                type="submit">
                Apply
              </Button>
            )}
          </div>
        </form>
      </Form>
    </div>
  );
};

export default CheckboxTree;

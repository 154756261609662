import React, { useState } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle
} from '@/components/ui/navigation-menu';
import TooltipWrapper from '@ui/tooltip/TooltipWrapper';
import {
  MdGeneratingTokens,
  SiAudiomack,
  GrDocumentConfig,
  IoStar,
  LuLayoutDashboard,
  RiTeamFill
} from '@icons';
import { FaBell } from 'react-icons/fa';
import { Button } from '@/components/ui/button';
//import { MdScreenSearchDesktop } from 'react-icons/md';

export const menu = [
  { title: 'Unwink', path: '/' },
  {
    title: 'Feedback analysis',
    path: '/csat',
    icon: <MdGeneratingTokens size="24px" />,
    new: false
  },
  {
    title: 'Conversation analysis',
    path: '/conversation',
    disabled: true,
    icon: <SiAudiomack size="24px" />,
    new: false
  }
  // {
  //   title: 'Questionnaire analytics',
  //   path: '/questionnaire',
  //   disabled: true,
  //   icon: <LuLayoutDashboard size="24px" />,
  //   new: false
  // },
  // {
  //   title: 'Teams scorecard',
  //   path: '/teams-scorecard',
  //   disabled: true,
  //   icon: <RiTeamFill size="24px" />,
  //   new: false
  // },
  // {
  //   title: 'Script configurations',
  //   path: '/script-config',
  //   disabled: true,
  //   icon: <GrDocumentConfig size="24px" />,
  //   new: false
  // },
  // {
  //   title: 'Call analytics',
  //   path: '/call-analytics',
  //   disabled: true,
  //   icon: <IoStar size="24px" />,
  //   new: false
  // },
  // {
  //   title: 'Web monitoring',
  //   path: '/web-monitoring',
  //   disabled: true,
  //   icon: <MdScreenSearchDesktop size="24px" />,
  //   new: false
  // }
];

const disabledLinkProps: Partial<LinkProps> = {
  style: { pointerEvents: 'none', textDecoration: 'none', opacity: 0.5 },
  onClick: (event) => event.preventDefault()
};

const SidebarMenu = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const isActiveLink = (path: string): boolean => {
    return location.pathname.includes(path);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cn(
        'pl-30 relative hidden sm:flex items-baseline h-full duration-300',
        {
          'w-70': open,
          'w-0': !open
        }
      )}>
      <NavigationMenu className="w-74 m-w-full fixed bg-white border-r-2 border-r-gray-100 py-4 px-2 left-0 h-full items-start flex z-20">
        <NavigationMenuList className="flex flex-col justify-start items-start m-w-full pr-2">
          {menu.slice(1).map((menuItem, index) => (
            <NavigationMenuItem className="w-full ml-1" key={index}>
              <Link
                to={menuItem.path}
                style={
                  menuItem.disabled
                    ? { ...disabledLinkProps.style, opacity: 1 }
                    : undefined
                }>
                <NavigationMenuLink
                  className={cn(
                    navigationMenuTriggerStyle(),
                    'w-full justify-center h-14 rounded-[100px] hover:bg-gray-100',
                    {
                      'bg-lightCyan': isActiveLink(menuItem.path)
                    }
                  )}>
                  <TooltipWrapper
                    text={menuItem.title}
                    className={cn('absolute left-6 -top-2', {
                      hidden: open
                    })}>
                    <div
                      className={cn('flex justify-start items-start', {
                        'opacity-50': menuItem.disabled
                      })}>
                      {menuItem.icon}
                      {!open && menuItem.new && (
                        <span className="text-main text-[12px] mt-[-10px] font-medium pl-6  absolute">
                          new
                        </span>
                      )}
                    </div>
                  </TooltipWrapper>
                  <div
                    className={cn(
                      'origin-left duration-300 hover:block w-48 text-left ml-6 relative',
                      {
                        hidden: !open
                      }
                    )}>
                    <span className={cn({ 'opacity-50': menuItem.disabled })}>
                      {menuItem.title}
                    </span>
                    {menuItem.new && (
                      <span className="text-main text-[12px] mt-[-10px] font-medium pl-1  absolute">
                        new
                      </span>
                    )}

                    {menuItem.disabled && (
                      <span className="text-[8px] pl-1 -top-2 absolute font-bold text-main text-right word-wrap leading-none !opacity-100">
                        COMING
                        <br />
                        SOON
                      </span>
                    )}
                  </div>
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
          ))}

          <Button rounded="full" className="mt-6 w-full h-14" disabled>
            <TooltipWrapper
              text="Add"
              className={cn('absolute left-6 -top-2', {
                hidden: open
              })}>
              <FaBell size="24px" />
            </TooltipWrapper>
            <span
              className={cn('origin-left duration-300  w-60 -ml-8', {
                hidden: !open
              })}>
              Notify me
            </span>
          </Button>
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  );
};

export default SidebarMenu;

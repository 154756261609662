import React, { FC, useState } from 'react';
import { cn } from '@/lib/utils';
import useDateFilter from '@hooks/useDateFilter';
import { validateEmail } from '@utils/inputValidateHelper';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Button, ButtonVariant } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import DatePickerWithRange from '@ui/forms/DatePickerWithRange';
import SchedulingDropdown from '@ui/combobox/SchedulingDropdown';
import { toaster } from '@ui/dialogs/ToastErrorMessage';

const onChangeFilters = () => {};

interface INameDialogProps {
  onApply: (value: string) => void;
  maxCharacters?: number;
  children: React.ReactNode;
}

const ReportNotificationsDialog: FC<INameDialogProps> = ({
  onApply,
  children,
  maxCharacters = Infinity
}) => {
  const [value, setValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const { onDateChange, defaultDateValues, dateValues } = useDateFilter({
    onChangeFilters
  });

  const isMaxLengthReached = value.length >= maxCharacters;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.length <= maxCharacters) {
      setValue(newValue);
      setIsEmailValid(validateEmail(newValue));
    }
  };
  const onApplyClick = () => {
    if (isEmailValid) {
      onApply(value);
      setIsOpen(false);
      toaster.success({
        title: 'Report sent!',
        text: 'The report on your space has been successfully sent.'
      });
    }
  };
  const onOpenChange = () => {
    setValue('');
    setIsEmailValid(true);
    setIsOpen(!isOpen);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && value.trim() !== '' && isEmailValid) {
      onApplyClick();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Report</DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-2">
            <Label htmlFor="link" className="sr-only">
              Email
            </Label>
            <Input
              value={value}
              placeholder="Enter user email"
              onChange={onInputChange}
              onKeyDown={handleKeyDown}
              className={!isEmailValid ? 'border-red-500' : ''}
            />
            {isEmailValid ? (
              <span
                className={cn('text-gray-500 text-xs', {
                  'text-main': isMaxLengthReached
                })}>
                {`Name is limited to ${maxCharacters} characters.`}
              </span>
            ) : (
              <span className="text-red-500 text-xs">
                Please enter a valid email address.
              </span>
            )}
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <SchedulingDropdown />
                <span className="text-gray-500 text-xs">
                  Please select a scheduling.
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <DatePickerWithRange
                  className="w-3/5"
                  defaultValue={defaultDateValues}
                  value={dateValues}
                  onChange={onDateChange}
                />
                <span className="text-gray-500 text-xs">
                  Please select a period.
                </span>
              </div>
            </div>
          </div>
        </div>
        <DialogFooter className="justify-end flex flex-row gap-2 items-end">
          <DialogClose asChild>
            <Button className="px-6" variant={ButtonVariant.SECONDARY}>
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild disabled={!value || !isEmailValid}>
            <Button className="px-6" onClick={onApplyClick}>
              Send
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ReportNotificationsDialog;

import axios from 'axios';
import { toaster } from '@ui/dialogs/ToastErrorMessage';

const apiUrl = process.env.REACT_APP_API_URL as string;
const clientId = process.env.REACT_APP_INTERCOM_CLIENT_ID as string;
const redirectURL = process.env.REACT_APP_INTERCOM_REDIRECT_URL as string;
const intercomAuthURL = process.env.REACT_APP_INTERCOM_AUTH_URL as string;

export default class IntercomAPI {
  // eslint-disable-next-line no-use-before-define
  private static instance: IntercomAPI;

  private host = apiUrl;

  private api: any;

  private constructor() {
    const token = localStorage.getItem('auth-token');
    this.api = axios.create({
      baseURL: this.host,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  public static getInstance(): IntercomAPI {
    if (!IntercomAPI.instance) {
      IntercomAPI.instance = new IntercomAPI();
    }

    return IntercomAPI.instance;
  }

  public async getToken(code: string): Promise<null | void> {
    const response = await this.api
      .get(`/intercom/auth-code?code=${code}`)
      .catch((error: any) => {
        toaster.error({ text: 'Cannot connect to Intercom' });
        console.error(error.toJSON());
      });
    if (response?.data?.access_token) {
      localStorage.setItem('intercomToken', response.data.access_token);
      window.close();
    }
    return null;
  }

  public async syncData(spaceId: string, startDate: number): Promise<any> {
    const intercomToken = localStorage.getItem('intercomToken');
    try {
      const response = await this.api.post(
        `/intercom/${spaceId}/sync/init`,
        { startDate },
        {
          headers: {
            'x-intercom-token': intercomToken
          }
        }
      );
      return true;
    } catch (error: any) {
      toaster.error({ text: 'Cannot sync Intercom' });
      console.error(error.toJSON());
      return null;
    }
  }

  public async getSyncStatus(spaceId: string): Promise<any> {
    const intercomToken = localStorage.getItem('intercomToken');
    const response = await this.api
      .post(
        `/intercom/${spaceId}/sync/status`,
        {},
        {
          headers: {
            'x-intercom-token': intercomToken
          }
        }
      )
      .catch((error: any) => {
        toaster.error({ text: 'Cannot sync Intercom' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status as number)) {
      return response.data;
    }
    return null;
  }

  public getAuthURL(state: string): string {
    return `${intercomAuthURL}?client_id=${clientId}&state=${state}&redirect_uri=${redirectURL}`;
  }
}

import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { getColumns, getTableData } from '@utils/tableHelper';
import CsatAPI from '@api/csatAPI';
import { SpaceContext, SpaceContextType } from '@context/contexts';
import { IFilters } from '@all-types/csat-types';

interface ITableDataProps {
  appliedFilters?: Partial<IFilters>;
  triggerRefetch?: boolean;
}

const useTableData = ({ appliedFilters, triggerRefetch }: ITableDataProps) => {
  const csatAPI = CsatAPI.getInstance();
  const { dataSchema, currentSpace } =
    useContext<SpaceContextType>(SpaceContext);
  const currentSpaceId = currentSpace?.id;
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  });

  const {
    data: feedbackResponse,
    refetch: refetchFeedbacks,
    isLoading
  } = useQuery({
    queryKey: [
      'feedbacks',
      currentSpaceId,
      appliedFilters,
      pagination.pageIndex,
      pagination.pageSize
    ],
    queryFn: () =>
      csatAPI.getFeedbacks(
        currentSpaceId as string,
        appliedFilters,
        pagination.pageIndex,
        pagination.pageSize
      ),
    enabled: !!currentSpaceId
  });

  useLayoutEffect(() => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0
    }));
  }, [appliedFilters]);

  const [tableData, columns] = useMemo(() => {
    const data = feedbackResponse?.items;
    if (dataSchema && data?.length) {
      const columns = getColumns(data, dataSchema);
      const tableData = getTableData(data, dataSchema);
      return [tableData, columns];
    } else {
      return [[], []];
    }
  }, [feedbackResponse, dataSchema]);

  const refetchAll = async () => {
    await refetchFeedbacks();
  };

  useEffect(() => {
    if (triggerRefetch) {
      refetchAll();
    }
  }, [triggerRefetch]);

  return {
    columns,
    tableData,
    pagination,
    isLoading,
    refetchFeedbacks,
    rowCount: feedbackResponse?.total || 0,
    setPagination
  };
};

export default useTableData;
